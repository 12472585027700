.p-tabview {
  .p-tabview-nav {
    background: $tabviewNavBg;
    border: $tabviewNavBorder;
    border-width: $tabviewNavBorderWidth;

    li {
      margin-right: $tabviewHeaderSpacing;

      .p-tabview-nav-link {
        border: $tabviewHeaderBorder;
        border-width: $tabviewHeaderBorderWidth;
        border-color: $tabviewHeaderBorderColor;
        background: $tabviewHeaderBg;
        color: $tabviewHeaderTextColor;
        padding: $tabviewHeaderPadding;
        font-weight: $tabviewHeaderFontWeight;
        border-top-right-radius: $borderRadius;
        border-top-left-radius: $borderRadius;
        transition: $listItemTransition;
        margin: $tabviewHeaderMargin;
      }

      &:not(.p-highlight):not(.p-disabled):hover {
        .p-tabview-nav-link {
          background: $tabviewHeaderHoverBg;
          border-color: $tabviewHeaderHoverBorderColor;
          color: $tabviewHeaderTextHoverColor;
        }
      }

      &.p-highlight {
        .p-tabview-nav-link {
          background: $tabviewHeaderActiveBg;
          border-color: $tabviewHeaderActiveBorderColor;
          color: $tabviewHeaderTextActiveColor;
        }
      }
    }
  }

  .p-tabview-close {
    margin-left: $inlineSpacing;
  }

  .p-tabview-nav-btn.p-link {
    background: $tabviewHeaderActiveBg;
    color: $tabviewHeaderTextActiveColor;
    width: $buttonIconOnlyWidth;
    box-shadow: $raisedButtonShadow;
    border-radius: 0;

    &:focus {
      @include focused-inset();
    }
  }

  .p-tabview-panels {
    background: $tabviewContentBg;
    padding: $tabviewContentPadding;
    border: $tabviewContentBorder;
    color: $tabviewContentTextColor;
    border-bottom-right-radius: $borderRadius;
    border-bottom-left-radius: $borderRadius;
  }
}

.p-tabview-nav-content {
  scroll-behavior: smooth;
  scrollbar-width: none;
  overscroll-behavior: contain auto;
  position: relative;
}

.p-tabview-nav-link {
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  position: relative;
  text-decoration: none;
  overflow: hidden;
}

.p-tabview-nav {
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;
  flex: 1 1 auto;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-tabview-title {
  line-height: 1;
  white-space: nowrap;
}

.p-unselectable-text {
  user-select: none;
}

.p-tabview-nav-container {
  position: relative;
}