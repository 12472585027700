[data-amplify-authenticator] {
  --amplify-components-button-primary-background-color: var(--primary-button);
  --amplify-components-button-primary-hover-background-color: var(--primary-hover);
  --amplify-components-button-primary-focus-background-color: var(--primary-hover);
  --amplify-components-button-primary-active-background-color: var(--primary-hover);
  --amplify-components-button-link-color: var(--primary-button);
}

.amplify-button[data-variation='primary']:focus {
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px #9dc1fb, 0 1px 2px 0 #000;
}
