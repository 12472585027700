.loading-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full height of the viewport */
  text-align: center;
}

.loading-message {
  margin-top: 20px;
  font-size: 1.2rem;
  color: #555; /* Customize the color as needed */
}
