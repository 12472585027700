// Theme colors
// Primary
$primaryColor: #009ef7;
$primaryDarkColor: #0095e8;
$primaryDarkerColor: #0095e8;
$primaryTextColor: #f1faff;
$primaryTextDarkColor: #212e48;

// Secondary colors
$secondaryColor: #e1e3ea;
$secondaryDarkColor: #d6d6e1;
$secondaryDarkerColor: #b5b5c3;
$secondaryTextColor: #6c757d;
$secondaryTextDarkColor: #3f4254;

// Success
$successColor: #50cd89;
$successDarkColor: #47be7d;
$successDarkerColor: #47be7d;
$successTextColor: #e8fff3;
$successTextDarkColor: #1c3238;

// Info
$infoColor: #7239ea;
$infoDarkColor: #5014d0;
$infoDarkerColor: #5014d0;
$infoTextColor: #f8f5ff;
$infoTextDarkColor: #2f264f;

// Danger
$dangerColor: #f1416c;
$dangerDarkColor: #d9214e;
$dangerDarkerColor: #d9214e;
$dangerTextColor: #fff5f8;
$dangerTextDarkColor: #3a2434;

// Warning
$warningColor: #ffc700;
$warningDarkColor: #f1bc00;
$warningDarkerColor: #f1bc00;
$warningTextColor: #fff8dd;
$warningTextDarkColor: #392f28;

$gray-100-dark: #1b1b29;
$gray-200-dark: #2b2b40;
$gray-300-dark: #323248;
$gray-400-dark: #474761;
$gray-500-dark: #565674;
$gray-600-dark: #6d6d80;
$gray-700-dark: #92929f;
$gray-800-dark: #cdcdde;
$gray-900-dark: #ffffff;

$colors: (
  'blue': #2196f3,
  'green': #4caf50,
  'yellow': #fbc02d,
  'cyan': #00bcd4,
  'pink': #e91e63,
  'indigo': #3f51b5,
  'teal': #009688,
  'orange': #f57c00,
  'bluegray': #607d8b,
  'purple': #9c27b0,
  'red': #ff4032,
  'primary': $primaryColor,
);

// Mandatory Designer Variables
@import './variables/general';
@import './variables/form';
@import './variables/button';
@import './variables/panel';
@import './variables/_data';
@import './variables/_overlay';
@import './variables/_message';
@import './variables/_menu';
@import './variables/_media';
@import './variables/_misc';

:root {
  --surface-a: #ffffff;
  --surface-b: #f8f9fa;
  --surface-c: #e9ecef;
  --surface-d: #dee2e6;
  --surface-e: #ffffff;
  --surface-f: #ffffff;
  --text-color: #495057;
  --text-color-secondary: #6c757d;
  --primary-color: #{$primaryColor};
  --primary-color-text: #{$primaryTextColor};
  --font-family: #{$fontFamily};
  --surface-0: #ffffff;
  --surface-50: #fafafa;
  --surface-100: #f5f5f5;
  --surface-200: #eeeeee;
  --surface-300: #e0e0e0;
  --surface-400: #bdbdbd;
  --surface-500: #9e9e9e;
  --surface-600: #757575;
  --surface-700: #616161;
  --surface-800: #424242;
  --surface-900: #212121;
  --gray-50: #fafafa;
  --gray-100: #f5f5f5;
  --gray-200: #eeeeee;
  --gray-300: #e0e0e0;
  --gray-400: #bdbdbd;
  --gray-500: #9e9e9e;
  --gray-600: #757575;
  --gray-700: #616161;
  --gray-800: #424242;
  --gray-900: #212121;
  --content-padding: #{$panelContentPadding};
  --inline-spacing: #{$inlineSpacing};
  --border-radius: #{$borderRadius};
  --surface-ground: #f8f9fa;
  --surface-section: #ffffff;
  --surface-card: #ffffff;
  --surface-overlay: #ffffff;
  --surface-border: #dee2e6;
  --surface-hover: #e9ecef;
  --focus-ring: #{$focusShadow};
  --maskbg: #{$maskBg};
  --highlight-bg: #{$highlightBg};
  --highlight-text-color: #{$highlightTextColor};
  color-scheme: light;
}
