.p-checkbox {
  width: $checkboxWidth;
  height: $checkboxHeight;

  .p-checkbox-box {
    border: $checkboxBorder;
    background: $inputBg;
    width: $checkboxWidth;
    height: $checkboxHeight;
    color: $textColor;
    border-radius: $borderRadius;
    transition: $formElementTransition;

    .p-checkbox-icon {
      transition-duration: $transitionDuration;
      color: $checkboxIconActiveColor;
      font-size: $checkboxIconFontSize;
    }

    &.p-highlight {
      border-color: $checkboxActiveBorderColor;
      background: $checkboxActiveBg;

      &:not(.p-disabled):hover {
        border-color: $checkboxActiveHoverBorderColor;
        background: $checkboxActiveHoverBg;
        color: $checkboxIconActiveHoverColor;
      }
    }
  }

  &:not(.p-checkbox-disabled) {
    .p-checkbox-box {
      &:hover {
        border-color: $inputHoverBorderColor;
      }

      &.p-focus {
        @include focused-input();
      }

      &.p-highlight:hover {
        border-color: $checkboxActiveHoverBorderColor;
        background: $checkboxActiveHoverBg;
        color: $checkboxIconActiveHoverColor;
      }
    }
  }

  &.p-invalid>.p-checkbox-box {
    @include invalid-input();
  }
}

.p-input-filled {
  .p-checkbox {
    .p-checkbox-box {
      background-color: $inputFilledBg;

      &.p-highlight {
        background: $checkboxActiveBg;
      }
    }

    &:not(.p-checkbox-disabled) {
      .p-checkbox-box {
        &:hover {
          background-color: $inputFilledHoverBg;
        }

        &.p-highlight:hover {
          background: $checkboxActiveHoverBg;
        }
      }
    }
  }
}

@if ($highlightBg ==$checkboxActiveBg) {
  .p-highlight {
    .p-checkbox {
      .p-checkbox-box {
        border-color: $checkboxIconActiveColor;
      }
    }
  }
}

.p-checkbox {
  position: relative;
  display: inline-flex;
  user-select: none;
  vertical-align: bottom;
}

.p-checkbox {
  width: 22px;
  height: 22px;
}

.p-checkbox {
  position: relative;
  display: inline-flex;
  user-select: none;
  vertical-align: bottom;
}

.p-checkbox .p-checkbox-input {
  border: 2px solid #d1d5db;
  border-radius: 6px;
}

.p-checkbox-input {
  cursor: pointer;
}

.p-checkbox-input {
  appearance: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  z-index: 1;
  outline: 0 none;
  cursor: pointer;
}

.p-checkbox.p-highlight .p-checkbox-box {
  border-color: #06b6d4;
  background: #06b6d4;
}

.p-checkbox .p-checkbox-box {
  border: 2px solid #d1d5db;
  background: #ffffff;
  width: 22px;
  height: 22px;
  color: #4b5563;
  border-radius: 6px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  outline-color: transparent;
}

.p-checkbox-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon.p-icon {
  width: 14px;
  height: 14px;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
  transition-duration: 0.2s;
  color: #ffffff;
  font-size: 14px;
}

svg.p-icon {
  pointer-events: auto;
}

.p-icon {
  display: inline-block;
}

.p-checkbox:not(.p-disabled):has(.p-checkbox-input:hover) .p-checkbox-box {
  border-color: #06b6d4;
}

.p-checkbox:not(.p-disabled):has(.p-checkbox-input:focus-visible) .p-checkbox-box {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a5f3fc;
  border-color: #06b6d4;
}

.p-checkbox:not(.p-disabled):has(.p-checkbox-input:hover).p-highlight .p-checkbox-box {
  border-color: #0e7490;
  background: #0e7490;
  color: #ffffff;
}