/* LoginPage.css */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f9fa;
  margin: 0;
}

.login-card {
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.p-field {
  margin-bottom: 1.5rem;
}

.p-text-center {
  text-align: center;
}

.p-button-block {
  width: 100%;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px; /* Adjust as necessary for spacing */
}

.logo {
  height: 40px; /* Ensure the logo height remains as desired */
}
