.p-radiobutton {
  width: $radiobuttonWidth;
  height: $radiobuttonHeight;

  .p-radiobutton-box {
    border: $radiobuttonBorder;
    background: $inputBg;
    width: $radiobuttonWidth;
    height: $radiobuttonHeight;
    color: $textColor;
    border-radius: 50%;
    transition: $formElementTransition;

    &:not(.p-disabled):not(.p-highlight):hover {
      border-color: $inputHoverBorderColor;
    }

    &:not(.p-disabled).p-focus {
      @include focused-input();
    }

    .p-radiobutton-icon {
      width: $radiobuttonIconSize;
      height: $radiobuttonIconSize;
      transition-duration: $transitionDuration;
      background-color: $radiobuttonIconActiveColor;
    }

    &.p-highlight {
      border-color: $radiobuttonActiveBorderColor;
      background: $radiobuttonActiveBg;

      &:not(.p-disabled):hover {
        border-color: $radiobuttonActiveHoverBorderColor;
        background: $radiobuttonActiveHoverBg;
        color: $radiobuttonIconActiveHoverColor;
      }
    }
  }

  &.p-invalid>.p-radiobutton-box {
    @include invalid-input();
  }

  &:focus {
    outline: 0 none;
  }
}

.p-input-filled {
  .p-radiobutton {
    .p-radiobutton-box {
      background-color: $inputFilledBg;

      &:not(.p-disabled):hover {
        background-color: $inputFilledHoverBg;
      }

      &.p-highlight {
        background: $radiobuttonActiveBg;

        &:not(.p-disabled):hover {
          background: $radiobuttonActiveHoverBg;
        }
      }
    }
  }
}

@if ($highlightBg ==$radiobuttonActiveBg) {
  .p-highlight {
    .p-radiobutton {
      .p-radiobutton-box {
        border-color: $radiobuttonIconActiveColor;
      }
    }
  }
}

.p-radiobutton {
  width: 22px;
  height: 22px;
}

.p-radiobutton .p-radiobutton-input {
  appearance: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  z-index: 1;
  outline: 0 none;
  border: 2px solid #d1d5db;
  border-radius: 50%;
}

.p-radiobutton-input {
  cursor: pointer;
}

.p-radiobutton {
  position: relative;
  display: inline-flex;
  user-select: none;
  vertical-align: bottom;
}

.p-radiobutton-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-radiobutton .p-radiobutton-box {
  border: 2px solid #d1d5db;
  background: #ffffff;
  width: 22px;
  height: 22px;
  color: #4b5563;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  outline-color: transparent;
}

.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  width: 12px;
  height: 12px;
  transition-duration: 0.2s;
  background-color: #ffffff;
}

.p-radiobutton.p-highlight .p-radiobutton-box {
  border-color: #06b6d4;
  background: #06b6d4;
}

.p-radiobutton.p-highlight .p-radiobutton-icon {
  transform: translateZ(0) scale(1, 1);
  visibility: visible;
}

.p-radiobutton:not(.p-disabled):has(.p-radiobutton-input:hover).p-highlight .p-radiobutton-box {
  border-color: #0e7490;
  background: #0e7490;
}

.p-radiobutton:not(.p-disabled):has(.p-radiobutton-input:hover) .p-radiobutton-box {
  border-color: #06b6d4;
}

.p-radiobutton:not(.p-disabled):has(.p-radiobutton-input:focus-visible) .p-radiobutton-box {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a5f3fc;
  border-color: #06b6d4;
}

.p-radiobutton:not(.p-disabled):has(.p-radiobutton-input:hover).p-highlight .p-radiobutton-box .p-radiobutton-icon {
  background-color: #ffffff;
}

.p-radiobutton-icon {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: translateZ(0) scale(0.1);
  border-radius: 50%;
  visibility: hidden;
}