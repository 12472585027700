@import '../src/assets/theme/themes/centro/variables';

:root {
  // CSS variables - potentially for whitelabeling
  --topbar-height: 60px;
  --topbar-bg-color: #ffffff;
  --sidebar-bg-color: #1e1e2d;
  --sidebar-separator-color: #393945;
  --sidebar-menu-heading-color: #646477;
  --sidebar-link-text-color: #9d9da6;
  --sidebar-link-text-color-active: #ffffff;
  --sidebar-link-text-color-hover: #ffffff;
  --sidebar-link-bg-color-active: #2a2a3c;
  --sidebar-min-width: 70px;
  --sidebar-max-width: 260px;
  --overlay-bg-color: #000000;
  --body-bg-color: #f5f8fa;
  --primary-button: #{$buttonBg};
  --primary-hover: #{$buttonHoverBg};

  font-size: 13px;
}

html {
  font-size: 13px;
  font-family: var(--font-family);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--body-bg-color);
}

code {
  font-family: var(--font-family);
}
