@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  // utility class for navbar scrollbar styling
  .nav-scrollbar::-webkit-scrollbar {
    width: 5px;
  }

  .nav-scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
  }

  .nav-scrollbar::-webkit-scrollbar-thumb {
    background: var(--sidebar-link-text-color);
    border-radius: 100vh;
  }
}
